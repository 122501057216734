import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useCheckUserPermissions } from 'helpers/hooks';
import styles from '../timeline.module.css';

export default function ChangeRequestMessage({
	changeRequestMessage,
	createdBy,
	metadata,
}) {
	const { user } = useSelector(({ user }) => user);
	const [shortMessage, setShortMessage] = useState(true);
	const { includeUsers } = useCheckUserPermissions();

	const onHandleLength = (bool) => {
		setShortMessage(bool);
	};

	const isOriginalCreator =
		user.id === createdBy || user.id === metadata?.createdBy;

	const isAllowedUser = includeUsers('agencyuser', 'csw') || isOriginalCreator;

	return isAllowedUser && changeRequestMessage ? (
		<div className={styles.changeRequestMessageRoot}>
			<div className={styles.changeRequestMessageTitle}>Requested Changes</div>
			<div className={styles.changeRequestMessageBody}>
				{changeRequestMessage && changeRequestMessage.length > 300 ? (
					changeRequestMessage &&
					changeRequestMessage.length >= 300 &&
					shortMessage ? (
						<>
							{changeRequestMessage.substring(0, 300)}
							<span
								className={styles.changeRequestMessageSeeMore}
								onClick={() => onHandleLength(false)}>
								{' '}
								...See More
							</span>
						</>
					) : (
						<>
							{changeRequestMessage}
							<span
								className={styles.changeRequestMessageSeeMore}
								onClick={() => onHandleLength(true)}>
								{' '}
								...See Less
							</span>
						</>
					)
				) : (
					changeRequestMessage
				)}
			</div>
		</div>
	) : null;
}
